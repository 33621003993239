import React from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EScholarship } from '@src/constants/sale/index';
import { cn } from '@/lib/utils';

const getPriceCard = (sale, price, duration, program) => {
  switch (sale) {
    case 'true':
      return (
        <div className="">
          <div className="flex min-h-[190px] flex-col items-start justify-center whitespace-nowrap rounded-[30px] bg-sale-orange-50 p-8 pb-20 text-black sm:w-7/12 md:w-full">
            {' '}
            <div className="rounded-[5px] bg-sale-blue-50 p-1 px-2 text-sm font-semibold uppercase text-v5-neutral-500">
              <p>For {duration}</p>
            </div>
            <div className="flex items-center gap-x-1">
              <h1 className="">
                <FontAwesomeIcon
                  icon={['fas', 'indian-rupee-sign']}
                  size="sm"
                  className="mr-2 text-sale-neutral-300"
                ></FontAwesomeIcon>
                {price}
              </h1>
              <p className=" mt-3 text-v5-green-300 sm:ml-6 md:text-sale-neutral-300">
                /month
              </p>
            </div>
            <h6 className="text-sale-neutral-500 ">Before Scholarship</h6>
          </div>
          <div className="relative flex h-[150px] -translate-y-10 items-start justify-center rounded-[20px] bg-sale-blue-300 md:h-[200px]">
            <div className="mx-2 mt-6 text-center font-manrope text-white">
              <h2 className="font-rubik text-base font-normal text-sale-orange-100 md:text-lg">
                <span className="font-bold italic">#AddProjectsToResume</span>{' '}
                and crack interviews.
              </h2>
              <p className="mt-2 text-sm font-black">
                Get a Rs.{EScholarship[program]} scholarship.
                <br />
                <span className="text-xs font-normal">Limited Time Offer.</span>
              </p>
            </div>
            <div className="absolute -bottom-[39px] left-1/2 h-[78px] w-[78px] -translate-x-1/2">
              <StaticImage
                loading="lazy"
                src="../../../images/v5/Sale/Card-Motif.png"
                placeholder="blurred"
                alt="Sale Motif"
                width={180}
              />
            </div>
          </div>
        </div>
      );

    default:
      return (
        <div className="flex  min-h-[190px] w-full flex-col items-start justify-center whitespace-nowrap rounded-[30px] bg-v5-green-100 p-8 text-black sm:min-h-full sm:w-[54%]">
          {' '}
          <div className="rounded-[5px] bg-v5-yellow-300 p-1 px-2 text-sm font-semibold uppercase text-v5-neutral-500">
            <p>For {duration}</p>
          </div>
          <div className="flex items-center gap-x-1">
            <h1 className="">
              <FontAwesomeIcon
                icon={['fas', 'indian-rupee-sign']}
                size="sm"
                className="mr-2 text-v5-green-300"
              ></FontAwesomeIcon>
              {price}
            </h1>
            <p className=" mt-3 text-v5-green-300 sm:ml-4">/month</p>
          </div>
          <p className="-mt-2 text-sm text-v5-green-300">(For 36 months)</p>
          <h6 className="mt-3 text-v5-neutral-500 ">Before Scholarship</h6>
        </div>
      );
  }
};

const PricingCardSection = React.memo(function PricingCardSection({
  price,
  duration,
  ...props
}) {
  return (
    <SectionLayout heading={<>Program Fee and Scholarships</>} {...props}>
      <div className="container flex justify-center">
        <div
          className={cn(
            'w-max rounded-[30px] p-8 text-v5-neutral-200 sm:px-12 ',
            `${props.dark ? 'bg-v5-green-500' : 'bg-v5-green-100'}`,
          )}
        >
          <div className="flex flex-wrap items-stretch  gap-x-8 gap-y-8 sm:flex-nowrap ">
            {getPriceCard(props.sale, price, duration, props.program)}
            <div className="h-full w-full self-center">
              <div className="mb-2 flex items-start gap-x-2">
                <div className="w-[20px] ">
                  <StaticImage
                    width={40}
                    loading="lazy"
                    src="../../../images/v5/ProgramCards/tick.png"
                    placeholder="none"
                    alt="Tick"
                    className="mt-1"
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </div>
                <div>
                  <h6 className=" ">A Free Trial Session</h6>
                  <p className="text-v5-neutral-400">No Fee Required</p>
                </div>
              </div>
              <div className="mb-4 flex items-start gap-x-2">
                <div className="w-[20px] ">
                  <StaticImage
                    width={40}
                    loading="lazy"
                    src="../../../images/v5/ProgramCards/tick.png"
                    placeholder="none"
                    alt="Tick"
                    className="mt-1"
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </div>
                <div>
                  <h6 className=" ">Assured Scholarships</h6>
                  <p className="text-v5-neutral-400">After Free Trial</p>
                </div>
              </div>
              <div className="text-sm leading-relaxed">
                <p className="mb-2">
                  Pay the success fee only if you land a job{' '}
                  <br className="hidden sm:block"></br>
                  within 6 months of <b>placement eligibility date</b>
                </p>
                <p className="text-v5-neutral-400">
                  Success fee is Rs. 19,999 if CTC is less than 15 LPA.
                  <br></br>
                  Success fee is Rs. 39,999 if CTC is greater than 15 LPA.
                  <br></br>12 month no-cost EMI and 18, 24 &amp; 36 month
                  low-cost EMI available.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionLayout>
  );
});

export default PricingCardSection;
